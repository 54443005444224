import axios from "axios";
import {
  CashbackCategoryType,
  CashbackSubCategoryType,
  CountryType,
  GenderType,
  StatusStepType,
} from "@/enums";
import { parseISO, differenceInDays } from "date-fns";
import {
  isAndroid as isAndroidDevice,
  isIOS as isIOSDevice,
  isMobile,
  isTablet,
} from "react-device-detect";
import messages from "@/messages/de/app.json";
import Status from "@/components/Status/Status";
import SuccessIcon from "public/icons/success.svg";
import SpinnerIcon from "public/icons/spinner.svg";
import CloseIcon from "public/icons/close.svg";
import InfoIcon from "public/icons/alert.svg";

export const rewardsAxios = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_API_DOMAIN}/v1/`,
  withCredentials: true,
});

export const getPlatform = () => {
  if ((isTablet || isMobile) && isIOSDevice) {
    return "iOS";
  }

  if ((isTablet || isMobile) && isAndroidDevice) {
    return "Android";
  }

  return "Desktop";
};

export const getPageableParams = (params: {
  desktop?: number;
  ios?: number;
  android?: number;
  sorting?: string;
  confirmation_token?: string;
  search?: string;
  selection?: string;
  selectedCategory?: number | null;
  selectedSubCategory?: number | null;
  resultsAmount?: number;
  results?: number;
  platform?: string;
  event_id?: string;
  page?: number;
  per_page?: number;
  status?: StatusStepType;
}) => {
  let mappedParams = new URLSearchParams();

  if (params.desktop !== undefined) {
    mappedParams.append("desktop", params.desktop.toString());
  }

  if (params.ios !== undefined) {
    mappedParams.append("ios", params.ios.toString());
  }

  if (params.android !== undefined) {
    mappedParams.append("android", params.android.toString());
  }

  if (params.sorting) {
    mappedParams.append("sorting", params.sorting.toString());
  }

  if (params.search) {
    mappedParams.append("q", params.search.toString());
  }

  if (params.event_id) {
    mappedParams.append("event_id", params.event_id.toString());
  }

  if (params.selectedCategory) {
    mappedParams.append("category_id", params.selectedCategory.toString());
  }

  if (params.selectedSubCategory) {
    mappedParams.append(
      "subcategory_id",
      params.selectedSubCategory.toString(),
    );
  }

  if (params.resultsAmount) {
    mappedParams.append("results", params.resultsAmount.toString());
  }

  if (params.results) {
    mappedParams.append("results", params.results.toString());
  }

  if (params.platform) {
    mappedParams.append("platform", params.platform.toString());
  }

  if (params.confirmation_token) {
    mappedParams.append(
      "confirmation_token",
      params.confirmation_token.toString(),
    );
  }

  if (params.page !== undefined) {
    mappedParams.append("page", params.page.toString());
  }

  if (params.per_page) {
    mappedParams.append("per_page", params.per_page.toString());
  }

  if (params.status !== undefined) {
    mappedParams.append("status", params.status.toString());
  }

  if (params.selection !== undefined) {
    mappedParams.append("selection", params.selection.toString());
  }

  return mappedParams;
};

export const getStatus = (
  status_step: StatusStepType,
  className?: string,
  withIcon?: boolean,
) => {
  switch (status_step) {
    case StatusStepType.Available:
      return (
        <Status
          className={className}
          style={{ color: "white", background: "#F27928" }}
          name="TaskStatus.available"
        />
      );
    case StatusStepType.Open:
      return (
        <Status
          className={className}
          style={{ color: "#F27928", background: "#FDE7D9" }}
          name="TaskStatus.open"
        />
      );
    case StatusStepType.Test:
      return (
        <Status
          icon={withIcon && <SpinnerIcon className="h-[16px] w-[16px]" />}
          spinningIcon
          className={className}
          style={{ color: "#E2AE00", background: "#FFF9C5" }}
          name="TaskStatus.test"
        />
      );
    case StatusStepType.Saved:
      return (
        <Status
          icon={withIcon && <SpinnerIcon className="h-[16px] w-[16px]" />}
          spinningIcon
          className={className}
          style={{ color: "#304CB2", background: "#E3EDFF" }}
          name="TaskStatus.saved"
        />
      );
    case StatusStepType.Confirmed:
      return (
        <Status
          icon={withIcon && <SuccessIcon className="h-[16px] w-[16px]" />}
          className={className}
          style={{ color: "white", background: "#30B28C" }}
          name="TaskStatus.confirmed"
        />
      );
    case StatusStepType.Rejected:
      return (
        <Status
          icon={
            withIcon && <CloseIcon className="h-[16px] w-[16px] scale-75" />
          }
          className={className}
          style={{ color: "#E16158", background: "#F7D7D5" }}
          name="TaskStatus.rejected"
        />
      );
    case StatusStepType.Feedback:
      return (
        <Status
          icon={
            withIcon && <InfoIcon className="h-[16px] w-[16px] rotate-180" />
          }
          className={className}
          style={{ color: "#737373", background: "#F5F5F5" }}
          name="TaskStatus.feedback"
        />
      );
    default:
      return null;
  }
};

export const getHistoryItemName = (payoutType: number, name: string) => {
  switch (payoutType) {
    case 1:
      return name;
    case 3:
      return name;
    case 4:
      return "BalanceHistoryStatus.bonus";
    case 5:
      return "BalanceHistoryStatus.survey";
    case 6:
      return name;
    case 7:
      return "BalanceHistoryStatus.redeem";
    case 8:
      return "BalanceHistoryStatus.payout";
    default:
      return name;
  }
};

export const getErrorMessage = (type: string) => {
  switch (type) {
    case "invalid":
      return "Errors.userInvalid";
    case "not_found_in_database":
      return "Errors.userNotFoundInDatabase";
    case "locked":
      return "Errors.userLocked";
    case "last_sign_in_ip":
      return "Errors.registerLastSignInIp";
    case "ip_address":
      return "Errors.registerIpAddress";
    case "country":
      return "Errors.registerCountry";
    default:
      return "Errors.userOther";
  }
};

export const getGender = (genderId: number) => {
  switch (genderId) {
    case GenderType.Male:
      return "male";
    case GenderType.Female:
      return "female";
    case GenderType.Other:
      return "other";
  }
};

export const getCashbackCategory = (categoryId: number | null) => {
  switch (categoryId) {
    case CashbackCategoryType.Fashion:
      return "fashion";
    case CashbackCategoryType.Food:
      return "food";
    case CashbackCategoryType.Tech:
      return "tech";
    case CashbackCategoryType.Internet:
      return "internet";
    case CashbackCategoryType.Travel:
      return "travel";
    case CashbackCategoryType.Beauty:
      return "beauty";
    case CashbackCategoryType.Hobby:
      return "hobby";
    case CashbackCategoryType.Kids:
      return "kids";
    case CashbackCategoryType.Presents:
      return "presents";
    case CashbackCategoryType.Finance:
      return "finance";
    case CashbackCategoryType.Delivery:
      return "delivery";
    default:
      return "all";
  }
};

export const getCashbackSubCategory = (subCategoryId: number | null) => {
  switch (subCategoryId) {
    case CashbackSubCategoryType.Genders:
      return "genders";
    case CashbackSubCategoryType.Shoes:
      return "shoes";
    case CashbackSubCategoryType.Sportswear:
      return "sportswear";
    case CashbackSubCategoryType.Accessories:
      return "accessories";
    case CashbackSubCategoryType.Supermarket:
      return "supermarket";
    case CashbackSubCategoryType.Alcohol:
      return "alcohol";
    case CashbackSubCategoryType.Coffee:
      return "coffee";
    case CashbackSubCategoryType.Specials:
      return "specials";
    case CashbackSubCategoryType.Delivery:
      return "delivery";
    case CashbackSubCategoryType.Electronics:
      return "electronics";
    case CashbackSubCategoryType.Software:
      return "software";
    case CashbackSubCategoryType.Family:
      return "family";
    case CashbackSubCategoryType.Furniture:
      return "furniture";
    case CashbackSubCategoryType.Garden:
      return "garden";
    case CashbackSubCategoryType.Office:
      return "office";
    case CashbackSubCategoryType.Zoo:
      return "zoo";
    case CashbackSubCategoryType.Tv:
      return "tv";
    case CashbackSubCategoryType.Mobile:
      return "mobile";
    case CashbackSubCategoryType.Hosting:
      return "hosting";
    case CashbackSubCategoryType.Streaming:
      return "streaming";
    case CashbackSubCategoryType.Flights:
      return "flights";
    case CashbackSubCategoryType.Bus:
      return "bus";
    case CashbackSubCategoryType.Apartment:
      return "apartment";
    case CashbackSubCategoryType.Tours:
      return "tours";
    case CashbackSubCategoryType.Tickets:
      return "tickets";
    case CashbackSubCategoryType.TravelGoods:
      return "travelGoods";
    case CashbackSubCategoryType.TravelOthers:
      return "travelOthers";
    case CashbackSubCategoryType.Cruise:
      return "cruise";
    case CashbackSubCategoryType.Pharma:
      return "pharma";
    case CashbackSubCategoryType.Cosmetics:
      return "cosmetics";
    case CashbackSubCategoryType.Supplements:
      return "supplements";
    case CashbackSubCategoryType.Lenses:
      return "lenses";
    case CashbackSubCategoryType.Bicycle:
      return "bicycle";
    case CashbackSubCategoryType.Auto:
      return "auto";
    case CashbackSubCategoryType.FanAccessories:
      return "fanAccessories";
    case CashbackSubCategoryType.Music:
      return "music";
    case CashbackSubCategoryType.Books:
      return "books";
    case CashbackSubCategoryType.Love:
      return "love";
    case CashbackSubCategoryType.Lotto:
      return "lotto";
    case CashbackSubCategoryType.Outdoor:
      return "outdoor";
    case CashbackSubCategoryType.SportingGoods:
      return "sportingGoods";
    case CashbackSubCategoryType.ChildrenGoods:
      return "childrenGoods";
    case CashbackSubCategoryType.Toys:
      return "toys";
    case CashbackSubCategoryType.Furnishing:
      return "furnishing";
    case CashbackSubCategoryType.FlowerDelivery:
      return "flowerDelivery";
    case CashbackSubCategoryType.PhotoGifts:
      return "photoGifts";
    case CashbackSubCategoryType.ExperienceVouchers:
      return "experienceVouchers";
    case CashbackSubCategoryType.Homemade:
      return "homemade";
    case CashbackSubCategoryType.OtherGifts:
      return "otherGifts";
    case CashbackSubCategoryType.Bank:
      return "bank";
    case CashbackSubCategoryType.CarInsurance:
      return "carInsurance";
    case CashbackSubCategoryType.Insurance:
      return "insurance";
    case CashbackSubCategoryType.Gas:
      return "gas";
    case CashbackSubCategoryType.OtherFinances:
      return "otherFinances";
    case CashbackSubCategoryType.Marketplaces:
      return "marketplaces";
    case CashbackSubCategoryType.Mail:
      return "mail";
    case CashbackSubCategoryType.SecondHand:
      return "secondHand";
    case CashbackSubCategoryType.Vpn:
      return "vpn";
  }
};

export const getCountry = (countryId: number | null): string | undefined => {
  const countries: { [key: string]: string } = messages.Countries;
  const countryKey = Object.keys(CountryType).find(
    (key) => CountryType[key as keyof typeof CountryType] === countryId,
  );
  const camelCaseCountryKey = countryKey
    ? countryKey.charAt(0).toLowerCase() + countryKey.slice(1)
    : undefined;

  return camelCaseCountryKey ? countries[camelCaseCountryKey] : undefined;
};

export const scrollTop = () => {
  window.scrollTo({ top: 0 });
};

export const formatNumberToThousands = (number: number) => {
  if (number >= 1000000) {
    return (number / 1000000).toFixed(0) + "Mio";
  } else if (number >= 1000) {
    return (number / 1000).toFixed(0) + "k";
  } else {
    return number.toString();
  }
};

export const calculateDaysAgo = (isoDateString: string): number => {
  const givenDate = parseISO(isoDateString);
  const currentDate = new Date();

  return differenceInDays(currentDate, givenDate);
};
