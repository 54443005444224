export enum OverviewSortingType {
  POPULARITY = "popularity",
  REWARDS_UP = "rewards_up",
  REWARDS_DOWN = "rewards_down",
  NEW = "new",
}

export enum StatusStepType {
  All = 0,
  Available = 2.5,
  Open = 2,
  Test = 3,
  Saved = 4,
  Confirmed = 5,
  Rejected = 6,
  Feedback = 7,
}

export enum StatusIdType {
  All = 0,
  Available = 1,
  Open = 2,
  Test = 3,
  Saved = 4,
  Confirmed = 5,
  Rejected = 6,
  Feedback = 7,
}

export enum CountryType {
  Andorra = 1,
  UnitedArabEmirates,
  Afghanistan,
  AntiguaAndBarbuda,
  Anguilla,
  Albania,
  Armenia,
  Angola,
  Antarctica,
  Argentina,
  AmericanSamoa,
  Austria,
  Australia,
  Aruba,
  AlandIslands,
  Azerbaijan,
  BosniaAndHerzegovina,
  Barbados,
  Bangladesh,
  Belgium,
  BurkinaFaso,
  Bulgaria,
  Bahrain,
  Burundi,
  Benin,
  SaintBarthelemy,
  Bermuda,
  BruneiDarussalam,
  Bolivia,
  Bonaire,
  Brazil,
  Bahamas,
  Bhutan,
  BouvetIsland,
  Botswana,
  Belarus,
  Belize,
  Canada,
  CoconutIslands,
  DemocraticRepublicOfCongo,
  CentralAfricanRepublic,
  Congo,
  Switzerland,
  IvoryCoast,
  CookIslands,
  Chile,
  Cameroon,
  China,
  Colombia,
  CostaRica,
  Cuba,
  CapeVerde,
  Curacao,
  ChristmasIslands,
  Cyprus,
  CzechRepublic,
  Germany,
  Djibouti,
  Denmark,
  Dominica,
  DominicanRepublic,
  Algeria,
  Ecuador,
  Estonia,
  Egypt,
  WesternSahara,
  Eritrea,
  Spain,
  Ethiopia,
  Finland,
  Fiji,
  FalklandIslands,
  Micronesia,
  FaroeIslands,
  France,
  Gabon,
  UnitedKingdom,
  Grenada,
  Georgia,
  FrenchGuyana,
  Guernsey,
  Ghana,
  Gibraltar,
  Greenland,
  Gambia,
  Guinea,
  Guadeloupe,
  EquatorialGuinea,
  Greece,
  SouthGeorgiaAndTheSouthSandwichIslands,
  Guatemala,
  Guam,
  GuineaBissau,
  Guyana,
  HongKong,
  HeardAndMcDonaldIslands,
  Honduras,
  Croatia,
  Haiti,
  Hungary,
  Indonesia,
  Ireland,
  Israel,
  IsleOfMan,
  India,
  BritishIndianOceanTerritory,
  Iraq,
  Iran,
  Iceland,
  Italy,
  Jersey,
  Jamaica,
  Jordan,
  Japan,
  Kenya,
  Kyrgyzstan,
  Cambodia,
  Kiribati,
  Comoros,
  SaintKittsAndNevis,
  NorthKorea,
  SouthKorea,
  Kuwait,
  CaymanIslands,
  Kazakhstan,
  Laos,
  Lebanon,
  SaintLucia,
  Liechtenstein,
  SriLanka,
  Liberia,
  Lesotho,
  Lithuania,
  Luxembourg,
  Latvia,
  Libya,
  Morocco,
  Monaco,
  Moldova,
  Montenegro,
  SaintMartin,
  Madagascar,
  MarshallIslands,
  NorthMacedonia,
  Mali,
  Myanmar,
  Mongolia,
  Macau,
  NorthernMarianaIslands,
  Martinique,
  Mauritania,
  Montserrat,
  Malta,
  Mauritius,
  Maldives,
  Malawi,
  Mexico,
  Malaysia,
  Mozambique,
  Namibia,
  NewCaledonia,
  Niger,
  NorfolkIsland,
  Nigeria,
  Nicaragua,
  Netherlands,
  Norway,
  Nepal,
  Nauru,
  Niue,
  NewZealand,
  Oman,
  Panama,
  Peru,
  FrenchPolynesia,
  PapuaNewGuinea,
  Philippines,
  Pakistan,
  Poland,
  StPierreAndMiquelon,
  Pitcairn,
  PuertoRico,
  Palestine,
  Portugal,
  Palau,
  Paraguay,
  Qatar,
  Reunion,
  Romania,
  Serbia,
  RussianFederation,
  Rwanda,
  SaudiArabia,
  SolomonIslands,
  Seychelles,
  Sudan,
  Sweden,
  Singapore,
  StHelena,
  Slovenia,
  SvalbardAndJanMayen,
  Slovakia,
  SierraLeone,
  SanMarino,
  Senegal,
  Somalia,
  Suriname,
  SouthSudan,
  SaoTomeAndPrincipe,
  ElSalvador,
  Syria,
  Eswatini,
  TurksAndCaicosIslands,
  Chad,
  FrenchSouthernAndAntarcticTerritories,
  Togo,
  Thailand,
  Tajikistan,
  Tokelau,
  TimorLeste,
  Turkmenistan,
  Tunisia,
  Tonga,
  Turkiye,
  TrinidadAndTobago,
  Tuvalu,
  Taiwan,
  Tanzania,
  Ukraine,
  Uganda,
  UnitedStatesMinorOutlyingIslands,
  Uruguay,
  VereinigteStaaten,
  Uzbekistan,
  HolySee,
  SaintVincentAndTheGrenadines,
  Venezuela,
  BritishVirginIslands,
  AmericanVirginIslands,
  Vietnam,
  Vanuatu,
  WallisAndFutuna,
  Samoa,
  Yemen,
  Mayotte,
  SouthAfrica,
  Zambia,
  Zimbabwe,
  UnitedStates,
}

export enum GenderType {
  Male = 1,
  Female = 2,
  Other = 3,
}

export enum CashbackCategoryType {
  Fashion = 51,
  Food = 52,
  Tech = 53,
  Internet = 54,
  Travel = 55,
  Beauty = 56,
  Hobby = 57,
  Kids = 58,
  Presents = 59,
  Finance = 60,
  Delivery = 61,
}

export enum CashbackSubCategoryType {
  Genders = 1,
  Shoes,
  Sportswear,
  Accessories,
  Supermarket,
  Alcohol,
  Coffee,
  Specials,
  Delivery,
  Electronics,
  Software,
  Family,
  Furniture,
  Garden,
  Office,
  Zoo,
  Tv,
  Mobile,
  Hosting,
  Streaming,
  Flights,
  Bus,
  Apartment,
  Tours,
  Tickets,
  TravelGoods,
  TravelOthers,
  Cruise,
  Pharma,
  Cosmetics,
  Supplements,
  Lenses,
  Bicycle,
  Auto,
  FanAccessories,
  Music,
  Books,
  Love,
  Lotto,
  Outdoor,
  SportingGoods,
  ChildrenGoods,
  Toys,
  Furnishing,
  FlowerDelivery,
  PhotoGifts,
  ExperienceVouchers,
  Homemade,
  OtherGifts,
  Bank,
  CarInsurance,
  Insurance,
  Gas,
  OtherFinances,
  Marketplaces,
  Mail,
  SecondHand,
  Vpn,
}

export enum TaskType {
  Task = "Task",
  MultirewardTask = "MultirewardTask",
}

export enum TaskStepType {
  Overview = "Overview",
  Step = "Step",
  Feedback = "Feedback",
  Completion = "Completion",
}
